import React from 'react'

import { NextPage } from 'next'

import { Box, Flex } from '@brikl/design-core'

import { Auth0LoginForm } from '../../auth/components/Auth0LoginForm'
import { HeadTitle } from '../../core/components/HeadTitle'
import { useBreadcrumbs } from '../../core/services/useBreadcrumbs'

const breadcrumbs = ['login', 'index']

export const LoginComponent = () => {
  useBreadcrumbs(breadcrumbs)

  if (typeof window === 'undefined')
    return (
      <Box mx="auto" my="20vh">
        Loading...
      </Box>
    )

  return (
    <React.Fragment>
      <HeadTitle title={['Login']} />
      <Flex height="100%" alignItems="center" justifyContent="center">
        <Auth0LoginForm />
      </Flex>
    </React.Fragment>
  )
}

const LoginPage: NextPage = () => {
  return <LoginComponent />
}

export default LoginPage
