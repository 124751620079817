import React, { useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useRouter } from 'next/router'

import {
  Alert,
  AlertIcon,
  Box,
  Heading,
  Image,
  Link,
  Spacer,
  Stack,
  Text,
} from '@brikl/design-core'
import { BriklButton } from '@brikl/design-system'

export const Auth0LoginForm = () => {
  const router = useRouter()

  const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0()

  const needsOnboarding = router?.query?.s && router?.query?.userId

  const loginPath = window.location.origin + window.location.pathname

  const logoutUrl = 'https://dev.auth.brikl.com/logout'

  useEffect(() => {
    if (!isLoading) {
      console.log('isAuthenticated', isAuthenticated)

      // if (error) logout({ returnTo: loginPath })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  function handleLogin() {
    if (error) console.error(error)

    loginWithRedirect({
      redirectUri: loginPath,
    })
  }

  return (
    <Stack>
      <Image
        src="/static/images/brikl-logo.svg"
        alt="BRIKL"
        width="200px"
        height="auto"
        margin="0 auto 0 auto"
      />
      <Box
        mt={4}
        maxWidth={480}
        width="100%"
        bg="white"
        p={10}
        boxShadow="0px 2px 2px 0px rgba(0, 0, 0, 0.2)"
        borderRadius={3}
      >
        <Stack space="xxsmall">
          <Heading>{needsOnboarding ? 'Welcome' : 'Login'}</Heading>
          <Text>
            {needsOnboarding
              ? 'Login to finalize the setup of your store'
              : 'Access the dashboard with your credentials.'}
          </Text>
        </Stack>

        {error && (
          <React.Fragment>
            <Spacer space="xsmall" />
            <Alert status="error">
              <AlertIcon />
              {error?.message}
            </Alert>
            <Spacer space="small" />
            <Link href={logoutUrl} w="100%">
              <BriklButton w="100%">Logout</BriklButton>
            </Link>
          </React.Fragment>
        )}

        {!error && (
          <form>
            <Stack space="xsmall">
              <BriklButton
                mt={2}
                isLoading={isLoading || isAuthenticated}
                onClick={handleLogin}
                data-cy="login-button"
                width="100%"
              >
                Login
              </BriklButton>
            </Stack>
          </form>
        )}
      </Box>
    </Stack>
  )
}
